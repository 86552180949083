import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { UserContextProvider } from './context/UserContext';  // Import UserContextProvider
import Admin from './pages/Admin';
import GameMasterOracle from './pages/GameMasterOracle';
import CharacterWorksheet from "./pages/CharacterWorksheet";
import PageManagement from './admin_components/PageManagement';
import EditorComponent from './admin_components/EditorComponent';
import PageViewerComponent from './pages/PageViewerComponent';  // Import the viewer component

import DiceRoller from "./components/DiceRoller";
import About from "./components/About";

import SmilerEscape from './components/SmilerEscape';
import TableOfContents from "./pages/TableOfContents";

function App() {
    const location = useLocation();

    useEffect(() => {
        const domain = window.location.hostname;
        let pageTitle = 'Backrooms dot Net';

        // Extract the path segments from the pathname
        const pathSegments = location.pathname.split('/').filter(Boolean);

        if (pathSegments.length > 0) {
            // Convert segments to a readable format
            const formattedSegments = pathSegments.map(segment => {
                // Decode URL-encoded characters
                const decodedSegment = decodeURIComponent(segment);

                // Replace hyphens with spaces and capitalize words
                return decodedSegment
                    .split('-')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
            });

            // Build the page title
            pageTitle = `${formattedSegments.join(' - ')} (${domain})`;
        } else {
            // Default title for the home page
            pageTitle = `Home (${domain})`;
        }

        // Update the document title
        document.title = pageTitle;
    }, [location]);

    return (
        <UserContextProvider>
            <Routes>
                <Route path="/admin" element={<Admin />} />
                <Route path="/" element={<TableOfContents />} />

                <Route path="/pforacle" element={<GameMasterOracle />} />

                <Route path="/pf2e_character_worksheet" element={<CharacterWorksheet />} />
                <Route path="/dice" element={<DiceRoller />} />
                <Route path="/about" element={<About />} />
                <Route path="/smilers" element={<SmilerEscape />} />

                {/* Pages and page group handling */}
                <Route path="/admin/pages" element={<PageManagement />} />

                {/* Pass group prop to both PageViewerComponent and EditorComponent */}
                <Route path="/news/:pagename" element={<PageViewerComponent group="news" />} />
                <Route path="/admin/news/:pagename" element={<EditorComponent group="news" />} />

                <Route path="/ancestries/:pagename" element={<PageViewerComponent group="ancestries" />} />
                <Route path="/admin/ancestries/:pagename" element={<EditorComponent group="ancestries" />} />

                <Route path="/backgrounds/:pagename" element={<PageViewerComponent group="backgrounds" />} />
                <Route path="/admin/backgrounds/:pagename" element={<EditorComponent group="backgrounds" />} />

                <Route path="/backgrounds/:pagename" element={<PageViewerComponent group="backgrounds" />} />
                <Route path="/admin/backgrounds/:pagename" element={<EditorComponent group="backgrounds" />} />

                <Route path="/classes/:pagename" element={<PageViewerComponent group="classes" />} />
                <Route path="/admin/classes/:pagename" element={<EditorComponent group="classes" />} />

                <Route path="/entities/:pagename" element={<PageViewerComponent group="entities" />} />
                <Route path="/admin/entities/:pagename" element={<EditorComponent group="entities" />} />

                <Route path="/levels/:pagename" element={<PageViewerComponent group="levels" />} />
                <Route path="/admin/levels/:pagename" element={<EditorComponent group="levels" />} />

                <Route path="/rules/:pagename" element={<PageViewerComponent group="rules" />} />
                <Route path="/admin/rules/:pagename" element={<EditorComponent group="rules" />} />

                <Route path="/lore/:pagename" element={<PageViewerComponent group="lore" />} />
                <Route path="/admin/lore/:pagename" element={<EditorComponent group="lore" />} />

                <Route path="/archetypes/:pagename" element={<PageViewerComponent group="archetypes" />} />
                <Route path="/admin/archetypes/:pagename" element={<EditorComponent group="archetypes" />} />

                <Route path="/feats/:pagename" element={<PageViewerComponent group="feats" />} />
                <Route path="/admin/feats/:pagename" element={<EditorComponent group="feats" />} />

                <Route path="/companions/:pagename" element={<PageViewerComponent group="companions" />} />
                <Route path="/admin/companions/:pagename" element={<EditorComponent group="companions" />} />

                <Route path="/spells/:pagename" element={<PageViewerComponent group="spells" />} />
                <Route path="/admin/spells/:pagename" element={<EditorComponent group="spells" />} />

                <Route path="/items/:pagename" element={<PageViewerComponent group="items" />} />
                <Route path="/admin/items/:pagename" element={<EditorComponent group="items" />} />

                <Route path="/npcs/:pagename" element={<PageViewerComponent group="npcs" />} />
                <Route path="/admin/npcs/:pagename" element={<EditorComponent group="npcs" />} />

                <Route path="/powers/:pagename" element={<PageViewerComponent group="powers" />} />
                <Route path="/admin/powers/:pagename" element={<EditorComponent group="powers" />} />

                <Route path="/factions/:pagename" element={<PageViewerComponent group="factions" />} />
                <Route path="/admin/factions/:pagename" element={<EditorComponent group="factions" />} />

            </Routes>
        </UserContextProvider>
    );
}

export default App;
