import React, { useState, useEffect } from 'react';
import { Button, Form, Tab, Tabs, Spinner, Container, Row, Col } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import { saveToAPI, loadFromAPI } from '../api/contentApi';  // Import API functions
import { html as beautifyHtml } from 'js-beautify';  // Import js-beautify's HTML formatter
import { useUserContext } from '../context/UserContext'; // Import the context hook

const EditorComponent = ({ group }) => {
    const { adminSessionToken } = useUserContext(); // Get the auth token from the context

    const { pagename } = useParams();  // Only grab the 'pagename' from the URL
    const navigate = useNavigate();     // For redirecting if needed
    const [searchParams, setSearchParams] = useSearchParams();  // Access query params
    const [htmlContent, setHtmlContent] = useState('');
    const [view, setView] = useState('visual'); // 'visual' or 'html'
    const [loading, setLoading] = useState(false);
    const [quillContent, setQuillContent] = useState(''); // separate state for Quill editor content
    const createPage = searchParams.get('createPage') === 'true';  // Check if createPage flag is present

    useEffect(() => {
        loadContent();
    }, [pagename, group]);

    const loadContent = async () => {
        setLoading(true);
        try {
            console.log("loadContent ... group=" + group + "; pagename=" + pagename);
            const content = await loadFromAPI(group, pagename);  // Pass group and pagename
            setHtmlContent(beautifyHtml(content));  // Beautify HTML upon load
            setQuillContent(content);  // Sync both Quill and HTML views with loaded content
            // If successful, remove the createPage flag if it was set
            if (createPage) {
                setSearchParams({});  // Remove the createPage flag
            }
        } catch (error) {
            if (error.message.includes('404')) {
                if (createPage) {
                    // If createPage flag is set and 404 occurs, create a new page
                    await handleCreateNewPage();
                } else {
                    alert('Failed to load content.');
                }
            } else {
                alert('Failed to load content.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleCreateNewPage = async () => {
        try {
            const newContent = '<p>New page content</p>';  // Default content for new pages
            await saveToAPI(htmlContent, group, pagename, adminSessionToken);  // Ensure group is passed correctly when saving
            setHtmlContent(beautifyHtml(newContent));  // Load the new content into editor
            setQuillContent(newContent);  // Sync both Quill and HTML views with the new content
            alert('New page created successfully!');
            setSearchParams({});  // Remove the createPage flag after successful creation
        } catch (error) {
            alert('Failed to create new page.');
        }
    };

    const handleSave = async () => {
        setLoading(true);
        try {
            // Set htmlContent to the latest content from the Quill editor before saving
            const contentToSave = beautifyHtml(quillContent);
            setHtmlContent(contentToSave);  // Update htmlContent with the latest value from Quill

            await saveToAPI(contentToSave, group, pagename, adminSessionToken);  // Save the latest content
            alert('Content saved successfully!');
        } catch (error) {
            alert('Failed to save content.');
        } finally {
            setLoading(false);
        }
    };

    const handleRevert = () => {
        const confirmed = window.confirm('Are you sure you want to revert? All unsaved changes will be lost.');
        if (confirmed) {
            loadContent();  // Reload content from the server
        }
    };

    const handleViewChange = (key) => {
        if (key === 'html') {
            setHtmlContent(beautifyHtml(quillContent));  // Beautify Quill content when switching to HTML view
        } else if (key === 'visual') {
            setQuillContent(htmlContent);  // Sync Quill editor with the HTML content
        }
        setView(key);  // Change the view
    };

    const handleViewPage = () => {
        // Open a new tab with the view of the page in the correct group path
        const url = `/${group}/${encodeURIComponent(pagename)}`;  // View without admin prefix
        window.open(url, '_blank');  // Open in a new tab
    };

    const handleCancel = () => {
        navigate('/admin/pages');  // Navigate back to the Page Management page
    };

    return (
        <Container className="mt-4">
            <Row className="justify-content-center">
                <Col lg={10} md={12}>
                    <h2 className="text-center mb-4">Editing: {group}/{pagename}</h2>
                    {loading ? (
                        <div className="text-center">
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    ) : (
                        <>
                            <Tabs
                                activeKey={view}
                                onSelect={handleViewChange}  // Switch between views with formatting
                                className="mb-3"
                            >
                                {/* Visual Editor (ReactQuill) with dynamically calculated height */}
                                <Tab eventKey="visual" title="Visual">
                                    <div style={{ border: view === 'visual' ? '1px solid #ced4da' : 'none', borderRadius: '4px', padding: '5px' }}>
                                        <ReactQuill
                                            value={quillContent}  // Use the Quill content state here
                                            onChange={setQuillContent}
                                            theme="snow"
                                            placeholder="Write something..."
                                            style={{
                                                height: '400px',
                                                paddingBottom: '40px'
                                            }}  // Deduct toolbar height from editor box
                                        />
                                    </div>
                                </Tab>

                                {/* HTML Editor with the styled box */}
                                <Tab eventKey="html" title="HTML">
                                    <Form.Control
                                        as="textarea"
                                        rows={15}  // Increased rows for more visible content
                                        value={htmlContent}  // Use HTML content state for this view
                                        onChange={(e) => setHtmlContent(e.target.value)}
                                        style={{
                                            minHeight: '400px',
                                            fontFamily: 'monospace',
                                            whiteSpace: 'pre-wrap',
                                            backgroundColor: '#f8f9fa',
                                            padding: '10px',
                                            border: '1px solid #ced4da',  // Consistent border and padding for textarea
                                            borderRadius: '4px',
                                            resize: 'vertical',
                                            overflowY: 'auto'
                                        }}
                                    />
                                </Tab>
                            </Tabs>

                            <div className="d-flex justify-content-end mt-3">
                                <Button
                                    onClick={handleSave}
                                    variant="primary"
                                    disabled={loading}
                                    className="me-2"
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={handleRevert}
                                    variant="secondary"
                                    className="me-2"
                                    disabled={loading}
                                >
                                    Revert
                                </Button>
                                <Button
                                    onClick={handleViewPage}
                                    variant="info"
                                    disabled={loading}
                                >
                                    View
                                </Button>
                                <Button
                                    onClick={handleCancel}
                                    variant="danger"
                                    className="ms-2"
                                    disabled={loading}
                                >
                                    Groups
                                </Button>
                            </div>
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    );
};

export default EditorComponent;
