// src/api/contentApi.js

export const saveToAPI = async (htmlContent, group, pagename, adminSessionToken) => {
    console.log('auth-token='+ adminSessionToken+", htmlContent=" + htmlContent + ", group=" + group + ", pagename=" + pagename);
    try {
        const response = await fetch('/api/v1/pages/save-content', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'auth-token': adminSessionToken, // Include the auth-token in the body
                'html': htmlContent,
                'group': group,
                'pagename': pagename
            }),
        });
        if (!response.ok) {
            throw new Error('Failed to save content');
        }
    } catch (error) {
        console.error('Error in saveToAPI:', error);
        throw error;
    }
};



export const loadFromAPI = async (group, pagename) => {
    try {
        const response = await fetch(`/api/v1/pages/load-content?group=${group}&pagename=${encodeURIComponent(pagename)}`);
        console.log('API Response Status:', response.status);
        console.log('API Response Content-Type:', response.headers.get('content-type'));

        if (!response.ok) {
            throw new Error(`Failed to load content. Status: ${response.status}`);
        }

        const data = await response.json();
        console.log('API Response Data:', data);
        return data.content || '';  // Use `data.content` as that's how the page data is structured
    } catch (error) {
        console.error('Error in loadFromAPI:', error);
        throw error;
    }
};
